import { useAuth } from 'oidc-react';
import React, { useContext, useEffect, useState, useRef } from 'react';
import Div100vh from 'react-div-100vh';
import { GameStateContext } from '../context/context';
import iconsConfig from './builder/config';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {
    addUserMessage,
    addResponseMessage,
    deleteMessages,
    setBadgeCount,
    isWidgetOpened,
    toggleMsgLoader,
    renderCustomComponent,
    Widget,
    markAllAsRead
} from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';

import './rcw.css';
import "./desktop.css";
import config from "../_shared/config";
import { useWindowSize, qna, addDelayedResponseMessage, replaceTokens, getSavedMessages, saveMessages } from '../_shared/utils';
import Builder from './builder/Builder';
import { contents } from './contents/content';

const {
    base_url,
    assets: {
        hakdbd_jenhornigolderchat_desktop, hakdbd_jenhornigolderchat_mobile, hakdbd_desktopbackground
    }, desktop_icons } = config;

const MessageAudio = ({ id }) => {
    return (<AudioPlayer style={{ width: "65%", "marginBottom": "15px" }} src={contents[replaceTokens(id)]} />)
}

const MessageVideo = ({ id }) => {
    return (<video style={{ width: "65%", "marginBottom": "15px" }} controls>
    <source src={contents[id]} />
    This browser does not support the HTML5 video element.
    </video>)
}

const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const saveUserMessage = (message, time = Date.now()) => {
    const savedMessages = getSavedMessages();
    savedMessages.push({
      type: 'user',
      time,
      message
    })
    saveMessages(savedMessages);
  }
  
  const saveAudioMessage = (type, id, time = Date.now()) => {
    const savedMessages = getSavedMessages();
    savedMessages.push({
      type,
      id,
      time
    })
    saveMessages(savedMessages);
  }
  
  const saveResponseMessage = (message, time = Date.now()) => {
    const savedMessages = getSavedMessages();
    savedMessages.push({
      type: 'response',
      time,
      message
    })
    saveMessages(savedMessages);
  }
  //Constant Variables
  const audioText = "solution audio";
  const videoText = "epilogue news clip";

  const hydrateChatBox = () => {
    let delay = 50;//ms
    const savedMessages = getSavedMessages();
    savedMessages.forEach((msg, index) => {
      if (msg.type === "user") {
        setTimeout(() => addUserMessage(msg.message), delay * index);
      }
      else if (msg.type === "response") {
        setTimeout(() => addResponseMessage(msg.message), delay * index);
      }
      else if (msg.id === audioText) {
        setTimeout(() => renderCustomComponent(MessageAudio, { id: msg.id }), (delay * index) + 60);
      }
      else if (msg.id === videoText) {
        setTimeout(() => renderCustomComponent(MessageVideo, { id: msg.id}), (delay * index) + 60);
      }
  
      if (savedMessages.length === index + 1)
        setTimeout(markAllAsRead, delay * index + 1)
    })
  }
  

const Desktop = ({ handleLogout }) => {
    const size = useWindowSize()
    const auth = useAuth();

    const [time, setTime] = useState("");

    const { gameState, addState, resetState } = useContext(GameStateContext);

    const chatImg = size.width > 800 ? hakdbd_jenhornigolderchat_desktop : hakdbd_jenhornigolderchat_mobile

    useEffect(() => {
        if ((auth && auth.userData)) {
            hydrateChatBox()
          }
        let now = new Date();
        let [hours, ampm] = now.getHours() > 12 ? [now.getHours() - 12, "PM"] : [now.getHours(), "AM"];
        setTime(`${hours}:${now.getMinutes()} ${ampm}`);
        const timer = setInterval(() => {
            let now = new Date();
            let [hours, ampm] = now.getHours() > 12 ? [now.getHours() - 12, "PM"] : [now.getHours(), "AM"];
            setTime(`${hours}:${now.getMinutes()} ${ampm}`);
        }, 30000);
        return () => clearInterval(timer);
       
    }, [auth && auth.userData]);

    const handleNewUserMessage = async (newMessage) => {
        try {
            saveUserMessage(newMessage);
            toggleMsgLoader();
            let response = await qna(newMessage, "01", "1");
            const data = await response.json();
            toggleMsgLoader();
            const chat = data?.answers[0]?.answer;
            if(chat.toLowerCase() === "no good match found in kb."){
                addDelayedResponseMessage("I’m not sure if it was them. Try looking at the clues and let me know who you think is guilty.");
            }
            else if (chat.toLowerCase().indexOf("{{incorrect}}") !== -1) {
                saveResponseMessage(replaceTokens(chat))
                addDelayedResponseMessage(replaceTokens(chat), 0);
                
            }
            else {
                saveResponseMessage(replaceTokens(chat))
                addDelayedResponseMessage(replaceTokens(chat), 0);
                

                sleep(2000).then(() => {
                    renderCustomComponent(MessageAudio, { id: audioText });
                    saveAudioMessage(audioText, audioText);
                });
                sleep(2000 * 2).then(() => {
                    renderCustomComponent(MessageVideo, { id: videoText });
                    saveAudioMessage(videoText, videoText);
                });

                // let correct = false;
                // chat?.split('+').forEach((element, index) => {
                    

                //     if (element.indexOf("{{Correct}}") > -1) correct = true;
                //     // Audio rendering
                //     if (element.toLowerCase().indexOf("solution audio") !== -1) {
                //         sleep(2000 * index).then(() => {
                //             renderCustomComponent(MessageAudio, { id: "Solution Audio" });
                //             saveAudioMessage(element.toLowerCase().trim(), replaceTokens(element.trim()));
                //         });
                //     //Video rendering
                //     } else if (element.toLowerCase().indexOf("epilogue news clip") !== -1){
                //         sleep(2000 * index).then(() => {
                //             renderCustomComponent(MessageVideo, { id: element.trim() });
                //             saveAudioMessage(element.toLowerCase().trim(), element.trim());
                //         });
                //     //Text message rendering
                //     }else {
                //         addDelayedResponseMessage(replaceTokens(element), index);
                //     }
                // });
            }
        }
        catch (error) {
            toggleMsgLoader();
            console.log(error);
            addDelayedResponseMessage("[Away Message] I’m not at the computer right now, sorry!");
        }
    }
    return (<div id="draggable-bounds" className={"desktop-container"} style={{ backgroundImage: `url(${base_url + hakdbd_desktopbackground})` }}>

        <Builder config={iconsConfig} />
        <div onClick={() => isWidgetOpened()}>
            <Widget
                handleNewUserMessage={handleNewUserMessage}
                title="Jen Horingolder"
                subtitle={null}
                senderPlaceHolder={"New Message"}
                launcherOpenImg={base_url + chatImg}
            />
        </div>
        <div className={"footer"}>
            <div onClick={handleLogout}>LOG OUT</div>
            <div>{time}</div>
        </div>
    </div>)

};

export default Desktop;